import { defineMessages } from 'react-intl-next';

export default defineMessages({
    feedbackWidget: {
        defaultMessage: 'Give feedback',
        description: 'The text for give feedback button for help centers management screen',
        id: 'sidebar.feedback.widget',
    },
    backButton: {
        defaultMessage: 'Back to all help centers',
        description: 'The text for the back button in the sidebar',
        id: 'sidebar.back.button',
    },
    helpCenterLabel: {
        defaultMessage: 'Help Center',
        description: 'Label for help center',
        id: 'sidebar.helpcenter.label',
    },
    settingsLabel: {
        defaultMessage: 'Settings',
        description: 'Label for settings',
        id: 'sidebar.settings.label',
    },
    sidebarNavigationLabel: {
        defaultMessage: 'Sidebar navigation',
        description: 'Label for sidebar navigation',
        id: 'sidebar.navigation.label',
    },
});
