import type { PropsWithChildren } from 'react';
import React from 'react';
import { NewGlobalBGColor } from '@helpCenter/view/styles/global';
import { isProjectMappingInHelpCenterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';
import { useMediaQuery } from 'view/help-center/use-screen-size/use-screen-size';
import * as grid from 'view/styles/grid';
import { sizes } from 'view/styles/sizes-viewport';
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar';
import LinkIcon from '@atlaskit/icon/glyph/link';
import PageIcon from '@atlaskit/icon/glyph/page';
import { Main, Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { SIDEBAR_WIDTH } from '../constants';
import { Header } from '../header';
import type { NavigationContentItem } from '../sidebar';
import { Sidebar } from '../sidebar';
import messages from './messages';

interface Props {
    featureName: string;
}
export const AdminManagementLayout: React.FC<PropsWithChildren<Props>> = ({ children, featureName }) => {
    const [{ route }, { push }] = useRouter();
    const { formatMessage } = useIntl();
    const { name: routeName } = route;
    const navigationContentItems: NavigationContentItem[] = isProjectMappingInHelpCenterEnabled()
        ? [
              {
                  onClick: () => push('/edit-details') as void,
                  text: formatMessage(messages.helpcenterDetailsItem),
                  isSelected: routeName === 'edit-details',
                  iconBefore: <PageIcon label={formatMessage(messages.helpcenterDetailsItem)} />,
              },
              {
                  onClick: () => push('/manage-projects') as void,
                  text: formatMessage(messages.helpcenterManageProjectsItem),
                  isSelected: routeName === 'manage-projects',
                  iconBefore: <LinkIcon label={formatMessage(messages.helpcenterManageProjectsItem)} />,
              },
              {
                  onClick: () => push('/reporting') as void,
                  text: formatMessage(messages.helpcenterVAReportingItem),
                  isSelected: routeName === 'reporting',
                  iconBefore: <GraphBarIcon label={formatMessage(messages.helpcenterVAReportingItem)} />,
              },
          ]
        : [];

    const isLaptop = !useMediaQuery(`(max-width: ${sizes.small}px)`);
    return (
        <Box xcss={PageLayoutWrapper} testId="admin-layout-management-page-wrapper">
            <PageLayout>
                <TopNavigation isFixed={false}>
                    <Header />
                </TopNavigation>
                <Content>
                    {isLaptop && (
                        <Sidebar
                            width={SIDEBAR_WIDTH}
                            navigationContentItems={navigationContentItems}
                            featureName={featureName}
                        />
                    )}
                    <Main>
                        <Box xcss={Container}>{children}</Box>
                    </Main>
                    <NewGlobalBGColor />
                </Content>
            </PageLayout>
        </Box>
    );
};

export default AdminManagementLayout;

const Container = xcss({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    maxWidth: grid.multiple(145).px,
    margin: '0 auto',
    paddingBottom: token('space.050', '4px'),
});

const PageLayoutWrapper = xcss({
    overflowAnchor: 'none',
    height: '100%',
});
