import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import defaultBannerImage from 'assets/images/default-banner.png';
import { useIntl } from 'react-intl-next';
import { useAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { LeftSidebarWithoutResize } from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';
import {
    Header,
    GoBackItem,
    ButtonItem,
    HeadingItem,
    Section,
    SideNavigation,
    NestableNavigationContent,
    NavigationHeader,
    NavigationContent,
} from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { HELP_CENTERS_MANAGEMENT_BASE_PATH } from '@atlassian/help-center-common-component/constants';
import FeedbackCollectorButton from '@atlassian/help-center-common-component/feedback-collector/feedback-collector-button';
import messages from './messages';
export interface NavigationContentItem {
    onClick: () => void;
    iconBefore?: React.ReactElement;
    text: string;
    isSelected?: boolean;
}

interface SidebarProps {
    width: number;
    navigationContentItems: NavigationContentItem[];
    featureName: string;
    currentHcName: string;
    currentHcBannerUrl: string | undefined;
    currentHcThemeColor: string;
    useDefaultBanner: boolean;
}
const Sidebar: React.FC<SidebarProps> = ({
    width,
    navigationContentItems,
    featureName,
    currentHcName,
    currentHcBannerUrl,
    currentHcThemeColor,
    useDefaultBanner,
}) => {
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const onBackButtonClick = useCallback(() => {
        createAnalyticsEvent({
            analyticsType: UI_EVENT_TYPE,
            action: 'click',
            actionSubjectId: `SidebarBackButton`,
        }).fire();

        window.location.href = HELP_CENTERS_MANAGEMENT_BASE_PATH;
    }, [createAnalyticsEvent]);

    const defaultImage = useDefaultBanner ? defaultBannerImage : undefined;
    const backgroundImage = currentHcBannerUrl ? currentHcBannerUrl : defaultImage;
    return (
        <LeftSidebarWithoutResize width={width}>
            <SideNavigation label={formatMessage(messages.sidebarNavigationLabel)}>
                <NavigationHeader>
                    <Header
                        iconBefore={
                            <HelpCenterImage
                                backgroundColor={currentHcThemeColor}
                                src={backgroundImage || defaultBannerImage}
                                height={24}
                                width={24}
                            />
                        }
                        description={formatMessage(messages.helpCenterLabel)}
                    >
                        {currentHcName}
                    </Header>
                </NavigationHeader>
                <Box paddingInlineStart="space.050">
                    <GoBackItem onClick={onBackButtonClick}>{formatMessage(messages.backButton)}</GoBackItem>
                </Box>
                <NestableNavigationContent showTopScrollIndicator>
                    <HeadingItem>{formatMessage(messages.settingsLabel)}</HeadingItem>
                    <Section isList>
                        {navigationContentItems.map(({ onClick, iconBefore, text, isSelected }) => (
                            <ButtonItem onClick={onClick} iconBefore={iconBefore} key={text} isSelected={isSelected}>
                                {text}
                            </ButtonItem>
                        ))}
                    </Section>
                </NestableNavigationContent>
                <Box xcss={navFooter}>
                    <NavigationContent showTopScrollIndicator>
                        <Section>
                            <FeedbackCollectorButton
                                showIcon
                                showTypeField
                                featureName={featureName}
                                buttonLabel={messages.feedbackWidget}
                                analyticsSubjectId="SidebarFeedbackButton"
                                useSideNavButton
                            />
                        </Section>
                    </NavigationContent>
                </Box>
            </SideNavigation>
        </LeftSidebarWithoutResize>
    );
};

interface HelpCenterImageProps {
    backgroundColor?: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpCenterImage = styled.img<HelpCenterImageProps>({
    borderRadius: '3px',
    objectFit: 'cover',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    backgroundColor: ({ backgroundColor }) =>
        !backgroundColor ? token('color.background.brand.bold', '#0074e0') : backgroundColor,
});

const navFooter = xcss({
    position: 'relative',
});

export default withAnalyticsContext({ component: 'SideBar' })(Sidebar);
